import * as React from 'react';
import styles from '../agtech.module.css';
import Layout from '../components/layout';
import Bullets from 'components/bullets';
import Tile from 'components/tile';
import GetConsultation from 'components/free-consultation';
import Blog from 'components/blog/widget';
import Booking from 'components/feedback';
import { Gokada } from 'components/carousel';
import identificators from 'components/googleAnalyticsIdentificators';

import { meta } from '../../../metaData';
import FAQ from 'components/faq';
import { useFaqData } from 'hooks/useFaqData';

const Routes = require('../../routes').Routes;

const LIST = [
  {
    title: 'Passenger App Features',
    text: 'Passengers can request a ride, get a fair cost calculation and view a trip history in their personal user account.',
  },
  {
    title: 'Web-Based Admin Panel',
    text: 'Effectively run your ride-sharing business and manage all processes via the admin panel. Monitor core statistics, view the details of rides and registered users and get insightful reports.',
  },
  {
    title: 'Robust Security Ecosystem',
    text: 'We design our products in compliance with the world-leading software development security standards to provide reliable protection against fraud and price-gouging.',
  },
  {
    title: 'Driver App Features',
    text: 'Car-sharing apps enable drivers to accept a ride request, share their trip with others in real-time, as well as schedule a future ride and find well-matched passengers.',
  },
  {
    title: 'Payment Systems Integration',
    text: 'We integrate our solutions with your local payment systems to enable cashless transfers that make a trip more comfy and accessible for both drivers and passengers.',
  },
  {
    title: 'Route Selection and Tracking Tools',
    text: 'We implement geo-location features and up-to-date GPS maps to provide the safety of the trips, as well as ensure optimal routes to the destination points while avoiding traffic jams.',
  },
];

const SERVICES = [
  {
    name: 'agritech-startup',
    title: 'Ride-Sharing Startup',
    text: 'Have a revolutionary idea? We are eager to create a project from scratch or perfect your existing MVP.',
    to: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  {
    name: 'agritech-b2b-company',
    title: 'Sharing Economy Companies',
    text: 'We gather a dedicated team to take care of every development step while saving your budget.',
    to: Routes.SERVICES_DEDICATED_TEAM,
  },
  {
    name: 'farm-owner',
    title: 'Taxi business',
    text: 'We embrace the latest tech trends to reshape your legacy infrastructure with digitally-enabled solutions.',
    to: Routes.SERVICES_DIGITAL_TRANSFORMATION,
  },
];

interface Props {
  pathContext: {
    prePost: ReadonlyArray<any>;
  };
  location: Location;
}

function RideSharing({ pathContext: { prePost }, location }: Props) {
  const faqData = useFaqData('allStrapiIndustry', 'Ride Sharing');

  return (
    <Layout
      title="Ride-sharing Software Development"
      subtitle="We empower your business with our custom-designed products and help you quickly enter the market, as well as get investment support thanks to our products’ innovative features that let you stay ahead of the curve."
      linkText="Estimate your project"
      metaData={{ main: meta.rideSharing, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.ESTIMATE_YOUR_PROJECT_HEADER}
    >
      <section className="section">
        <div className="inner">
          <h2 className="title">Ride-sharing solutions we deliver</h2>
          <p className="subtitle">
            Drive your ride-sharing business with our cost-effective products
          </p>
          <Bullets list={LIST} className={styles.bullets} />
        </div>
      </section>
      {/* <SwiperSlider> */}
      <Gokada />
      {/* </SwiperSlider> */}
      <section className="section">
        <div className="inner">
          <h2 className="title">Hire us to drive your business to success</h2>
          <p className="subtitle">Leave the development process to us</p>
          <Tile list={SERVICES} className={styles.imageBullets} />
        </div>
      </section>
      <GetConsultation
        title="Let’s hit the Transportation market together"
        text="Transforming trips into engaging experience"
        linkText="schedule a call"
        linkId={identificators.SCHEDULE_A_CALL_BANNER}
      />
      <section className="section">
        <div className="inner">
          <FAQ data={faqData} />
          <Blog posts={prePost} />
        </div>
      </section>
      <Booking />
    </Layout>
  );
}

export default RideSharing;
