import * as React from 'react';
import clsx from 'clsx';
import styles from './layout.module.css';
import Layout, { IMetaData } from 'components/layout';
import Header from './header';
import Footer from 'components/layout/footer';
import SubNavigation from 'components/layout/header/subnav';
import Contacts from 'pages/components/header/contacts';

const Routes = require('../../routes').Routes;

const subNav = [
  {
    title: 'Agritech',
    to: Routes.INDUSTRIES_AGRITECH,
  },
  {
    title: 'Martech',
    to: Routes.INDUSTRIES_MARTECH,
  },
  {
    title: 'Fintech',
    to: Routes.INDUSTRIES_FINTECH,
  },
  {
    title: 'Ride Sharing',
    to: Routes.INDUSTRIES_RIDESHARING,
  },
  {
    title: 'Foodtech',
    to: Routes.INDUSTRIES_FOODTECH,
  },
  {
    title: 'Edtech',
    to: Routes.INDUSTRIES_EDTECH,
  },
  {
    title: 'Proptech',
    to: Routes.INDUSTRIES_PROPTECH,
  },
  {
    title: 'Events management',
    to: Routes.INDUSTRIES_EVENTS_MANAGMENT,
  },
];

interface Props {
  title: string;
  subtitle: JSX.Element | string;
  children: React.ReactNode;
  metaData: IMetaData;
  pathname: string;
  linkId: string;
  linkText: string;
}

function Main({ title, subtitle, children, linkText, metaData, pathname, linkId }: Props) {
  return (
    <Layout title="Technology" navBtnTextColor="var(--red-color)" metaData={metaData}>
      <Header
        subNavigation={() => (
          <SubNavigation pathname={pathname} list={subNav} className={styles.subnav} />
        )}
      >
        <h1 className="header-title">{title}</h1>
        <p className="header-text">{subtitle}</p>
        <Contacts
          linkId={linkId}
          linkText={linkText}
          containerStyles={linkText.length > 15 ? styles.btnBox : styles.buttons}
          buttonStyles={linkText.length > 10 ? styles.btn : styles.contactBtn}
        />
      </Header>
      <main className={clsx('main', styles.main)}>{children}</main>
      <Footer />
    </Layout>
  );
}

export default Main;
